import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import arrowImage from './media/arrow.svg';

import styles from './OpenButtonMobile.module.scss';

const OpenButtonMobile: FC<IProps> = (props) => {
    if (!props.isMobile) {
        return null;
    }

    return (
        <div className={classNames(styles.openButtonMobile, props.className)}>
            <img
                className={styles.arrowImage}
                src={arrowImage}
                alt={''}
            />
        </div>
    );
};

export default OpenButtonMobile;
