import {
    IItem,
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    CSSProperties,
    FC,
    useCallback,
    useMemo,
} from 'react';

import {
    useIsMobile,
} from 'helpers/hooks';

import Loading from '../Loading';

import EmptyResult from './EmptyResult';
import DataCell from './Row/DataCell';

import styles from './EntityTable.module.scss';

const EntityTable: FC<IProps<IItem>> = (props) => {
    const isMobile = useIsMobile();

    const columns = useMemo(() => {
        if (isMobile) {
            return props.columnsMobile;
        }

        return props.columnsDesktop;
    }, [isMobile, props.columnsMobile, props.columnsDesktop]);

    const totalColSpan = useMemo(() => {
        if (isMobile) {
            if (props.maxRowWidthMobile) {
                return props.maxRowWidthMobile;
            }
        }
        if (props.maxRowWidthDesktop) {
            return props.maxRowWidthDesktop;
        }

        return columns
            .reduce((res, column) => {
                return res + props.formatColumnSpan(column, isMobile);
            }, 1);
    }, [columns]);

    const Row = useCallback((newProps: any) => {
        return (<props.row {...newProps}/>);
    }, []);

    const gridStyle: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: `repeat(${totalColSpan}, 1fr)`,
    };

    return (
        <div className={classNames(styles.entityTable, props.className)}>
            <div className={classNames(styles.head, styles.row)}>
                <div
                    className={styles.headGrid}
                    style={gridStyle}
                >
                    {[
                        ...columns
                            .map((column) =>
                                <DataCell
                                    key={column}
                                    className={classNames(styles.cell, styles.headCell)}
                                    isMobile={isMobile}
                                    colSpan={props.formatColumnSpan(column, isMobile)}
                                    colWidth={props.formatColumnWidth?.(column, isMobile) ?? null}
                                    isMultiline={props.formatColumnMultiline?.(column, isMobile) ?? false}
                                    isBold={false}
                                >
                                    {props.formatColumnTitle(column, isMobile)}
                                </DataCell>
                            ),
                    ]}
                </div>
                <div
                    key={'options'}
                    className={styles.optionsFiller}
                />
            </div>
            {
                props.items ?
                    props.items.length ?
                        props.items.map((item, rowIndex) => {
                            return (
                                <Row
                                    key={item.id}
                                    className={styles.row}
                                    cellClassName={styles.cell}
                                    gridStyle={gridStyle}
                                    rowIndex={rowIndex}
                                    item={item}
                                    columnsEnum={props.columnsEnum}
                                    columns={columns}
                                    isMobile={isMobile}
                                    formatColumnSpan={props.formatColumnSpan}
                                    formatColumnWidth={props.formatColumnWidth}
                                    formatColumnMultiline={props.formatColumnMultiline}
                                    formatColumnBold={props.formatColumnBold}
                                />
                            );
                        }) :
                        <EmptyResult/> :
                    <Loading className={styles.loading}/>
            }
        </div>
    );
};

export default EntityTable;
