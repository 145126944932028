export const INSTANCE_PATH = '/accounts';

export enum RESPONSE_ERROR {
    DUPLICATED_NAME = 'DUPLICATED_NAME',
}

export enum STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ARCHIVED = 'ARCHIVED'
}

export enum TASK_TYPE {
    ACTIVATION = 'ACTIVATION',
    DEACTIVATION = 'DEACTIVATION',
}

export const SHOWN_THRESHOLD_WARNING = 1000 * 60 * 10;
export const SHOWN_THRESHOLD_ERROR = 1000 * 60 * 30;
