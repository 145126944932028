import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useRef,
    useState,
} from 'react';

import {
    useClickOutside,
} from 'tools/hooks';

import Navigation from '../Navigation';

import burgerImage from './media/burger.svg';

import styles from './NavigationMobile.module.scss';

const NavigationMobile: FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    useClickOutside(containerRef, () => {
        setIsOpen(false);
    });

    return (
        <div
            ref={containerRef}
            className={classNames(styles.navigationMobile, props.className)}
        >
            <img
                className={styles.burger}
                src={burgerImage}
                alt={''}
                onClick={() => setIsOpen(!isOpen)}
            />
            {
                isOpen &&
                <Navigation
                    className={styles.navigation}
                    onClick={() => setIsOpen(false)}
                />
            }
        </div>
    );
};

export default NavigationMobile;
