export function formatChildren(children: any, columns: string[], columnsEnum: Record<string, string>): any[] {
    const allColumns = Object.values(columnsEnum);
    const res: any[] = [];

    for (const column of columns) {
        const index = allColumns.indexOf(column);

        res.push(children[index]);
    }

    return res;
}
