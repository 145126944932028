import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './Separator.module.scss';

const Separator: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.separator, props.className)}>
            <div className={styles.line}/>
            {
                props.label &&
                <div className={styles.label}>
                    {props.label}
                </div>
            }
        </div>
    );
};

export default Separator;
