import {
    WINDOW_WIDTH,
} from 'tools/constants';
import {
    useWindowWidth,
} from 'tools/hooks';

export default function useIsMobile() {
    const {windowWidth} = useWindowWidth();

    return windowWidth < WINDOW_WIDTH.W1024;
}
