import {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';

export type IParams<ITab> = {
    tab: ITab;
};

interface IData<ITab, IFilters, IFiltersField> {
    getDefaultTab(): ITab;
    getDefaultFilters(filtersField: IFiltersField): IFilters;

    getRedirectUrl(tab: ITab): string;
    getFiltersField(filters: IFilters): IFiltersField;

    mapFiltersFieldToTab(filtersField: IFiltersField): ITab;
    mapTabToFiltersField(tab: ITab): IFiltersField;
}

export default function useTabbedFilters<ITab, IFilters, IFilterField>(data: IData<ITab, IFilters, IFilterField>) {
    // @ts-ignore
    const params = useParams<IParams<ITab>>(); // Сраный тип у либы не даёт дженерик нормально сделать
    const navigate = useNavigate();

    const [tab, setTab] = useState<ITab>(params.tab ?? data.getDefaultTab);

    const filtersField = useMemo(() => {
        return data.mapTabToFiltersField(tab);
    }, [tab]);

    const [filters, setFilters] = useState<IFilters>(() => data.getDefaultFilters(filtersField));

    const onChangeFilters = (filters: IFilters) => {
        let newTab = tab;
        const filtersField = data.getFiltersField(filters);
        const mappedFiltersField = data.mapFiltersFieldToTab(filtersField);

        if (newTab !== mappedFiltersField) {
            newTab = mappedFiltersField;
        }

        const url = data.getRedirectUrl(newTab);

        setFilters(filters);

        if (newTab === tab) {
            return;
        }

        setTab(newTab);
        navigate(url);
    };

    useEffect(() => {
        const url = data.getRedirectUrl(tab);

        navigate(url);
    }, []);

    return {
        filters,
        onChangeFilters,
    };
}
