export enum PATH {
    SIGN_IN = '/',

    PAYMENTS = '/payments',
    PAYMENT = '/payment',

    ACCOUNTS = '/accounts',
    ACCOUNT = '/account',

    AGENTS = '/agents',
    AGENT = '/agent',

    PAYMENT_METHODS = '/payment_methods',
    PAYMENT_METHOD = '/payment_method',

    MERCHANTS = '/merchants',
    MERCHANT = '/merchant',

    USERS = '/users',
    USER = '/user',

    ADMIN_PANEL = '/admin',
    SETTINGS = '/settings',
}
