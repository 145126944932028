import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import {
    CrossButton,
} from 'components/buttons';
import {
    Modal,
} from 'components/other';

import styles from './EntityModalMobile.module.scss';

const EntityModalMobile: FC<IProps> = (props) => {
    return (
        <Modal
            className={classNames(styles.entityModalMobile, props.className)}
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <div className={styles.top}>
                <div className={styles.title}>
                    {props.title}
                </div>
                <CrossButton onClick={props.onClose}/>
            </div>
            {props.children}
        </Modal>
    );
};

export default EntityModalMobile;
