import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useRef,
} from 'react';

import styles from './StaticCollapse.module.scss';

const StaticCollapse: FC<IProps> = (props) => {
    const contentRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={classNames(styles.staticCollapse, props.className, {[styles.isOpen]: props.isOpen})}
            style={{
                height: props.isOpen ? contentRef.current?.clientHeight : 0,
            }}
        >
            <div
                ref={contentRef}
                className={props.contentClassName}
            >
                {props.children}
            </div>
        </div>
    );
};

export default StaticCollapse;
