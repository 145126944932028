import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import arrowImage from './media/arrow.svg';

import styles from './CollapseToggleMobile.module.scss';

const CollapseToggleMobile: FC<IProps> = (props) => {
    return (
        <div
            className={classNames(styles.collapseToggleMobile, props.className, {[styles.isOpen]: props.isOpen})}
            onClick={() => props.onChange(!props.isOpen)}
        >
            <div className={styles.text}>
                {
                    props.isOpen ?
                        'Hide filters' :
                        'More filters'
                }
                <img
                    className={styles.arrowImage}
                    src={arrowImage}
                    alt={''}
                />
            </div>
            <div className={styles.line}/>
        </div>
    );
};

export default CollapseToggleMobile;
