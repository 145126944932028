import {
    useEffect,
    useState,
} from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';

type IParsedParams<IParams extends Record<string, string | undefined>> = ReturnType<typeof useParams<IParams>>

interface IData<ITab, IParams extends Record<string, string | undefined>> {
    getDefaultTab(params: IParsedParams<IParams>): ITab;
    getRedirectUrl(params: IParsedParams<IParams>, tab: ITab): string;
}

export default function useTabs<ITab, IParams extends Record<string, string | undefined>>(data: IData<ITab, IParams>) {
    const params = useParams<IParams>();

    const [tab, setTab] = useState<ITab>(() => data.getDefaultTab(params));

    const navigate = useNavigate();

    const onChangeTab = (tab: ITab) => {
        setTab(tab);

        const url = data.getRedirectUrl(params, tab);

        navigate(url);
    };

    useEffect(() => {
        const url = data.getRedirectUrl(params, tab);

        navigate(url);
    }, []);

    return {
        tab,
        onChangeTab,
    };
}
