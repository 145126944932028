import {
    IItem,
} from '../types';
import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useMemo,
} from 'react';

import {
    DotsButton,
} from 'components/buttons';

import DataCell from './DataCell';
import OpenButtonMobile from './OpenButtonMobile';

import {
    formatChildren,
} from './functions';

import styles from './Row.module.scss';

const Row: FC<IProps<IItem>> = (props) => {
    const children = useMemo(() => {
        return formatChildren(props.children, props.columns, props.columnsEnum);
    }, [props.children, props.columns, props.columnsEnum]);

    const onClick = () => {
        if (!props.isMobile) {
            return;
        }

        props.onOpenMobile?.();
    };

    return (
        <div
            className={classNames(styles.row, props.className)}
            onClick={onClick}
        >
            <div
                className={styles.content}
                style={props.gridStyle}
            >
                {[
                    ...props.columns
                        .map((column, columnIndex) =>
                            <DataCell
                                key={column}
                                className={props.cellClassName}
                                isMobile={props.isMobile}
                                colSpan={props.formatColumnSpan(column, props.isMobile)}
                                colWidth={props.formatColumnWidth?.(column, props.isMobile) ?? null}
                                isMultiline={props.formatColumnMultiline?.(column, props.isMobile) ?? false}
                                isBold={props.formatColumnBold?.(column, props.isMobile) ?? false}
                            >
                                {children?.[columnIndex]}
                            </DataCell>
                        ),
                ]}
            </div>
            <div className={styles.action}>
                {
                    props.isMobile ?
                        (
                            props.onOpenMobile &&
                            <OpenButtonMobile isMobile={props.isMobile}/>
                        ) :
                        (
                            props.optionsDesktop &&
                            <DotsButton
                                className={styles.dotsButton}
                                options={
                                    props.optionsDesktop.map((option) => {
                                        return {
                                            key: option.key,
                                            label: option.label,
                                            isHidden: option.isHidden,
                                            to: option.to,
                                            onClick: option.onClick,
                                        };
                                    })
                                }
                            />
                        )
                }
            </div>
        </div>
    );
};

export default Row;
