import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useMemo,
    useRef,
    useState,
} from 'react';

import styles from './DataCell.module.scss';

const DataCell: FC<IProps> = (props) => {
    const [hoveredDOMRect, setHoveredDOMRect] = useState<DOMRect | null>(null);

    const contentRef = useRef<HTMLDivElement>(null);

    const isOverflowed = useMemo<boolean>(() => {
        if (!contentRef.current) {
            return false;
        }

        return contentRef.current.clientWidth !== contentRef.current.scrollWidth;
    }, [contentRef.current]);
    const tooltipContent = useMemo(() => {
        if (typeof props.children === 'string') {
            return props.children
                .split('\n')
                .map((line, i) => (
                    <div key={i}>
                        {line}
                    </div>
                ));
        }

        return props.children;
    }, [props.children]);

    const onMouseEnter = () => {
        if (props.isMobile) {
            return;
        }

        setHoveredDOMRect(contentRef.current?.getBoundingClientRect() ?? null);
    };
    const onMouseLeave = () => {
        if (props.isMobile) {
            return;
        }

        setHoveredDOMRect(null);
    };

    return (
        <div
            ref={contentRef}
            className={
                classNames(styles.dataCell, props.className, {
                    [styles.isSingleLine]: !props.isMultiline,
                    [styles.isMultiline]: props.isMultiline,
                    [styles.isBold]: props.isBold,
                })
            }
            style={{
                width: props.colWidth ?? undefined,
                gridColumn: `span ${props.colSpan}`,
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {props.children}
            {
                isOverflowed &&
                hoveredDOMRect &&
                <div
                    className={styles.tooltip}
                    style={{
                        top: hoveredDOMRect.top,
                        left: hoveredDOMRect.left,
                    }}
                >
                    {tooltipContent}
                </div>
            }
        </div>
    );
};

export default DataCell;
