import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useMemo,
    useState,
} from 'react';

import {
    useTimeout,
} from 'tools/hooks';

import {
    COPIED_DELAY_MS,
} from './constants';

import checkImage from './media/check.svg';

import styles from './ValueDisplay.module.scss';

const ValueDisplay: FC<IProps> = (props) => {
    const [isCopied, setIsCopied] = useState(false);

    const onCopy = async () => {
        setIsCopied(true);
        await navigator.clipboard.writeText(`${props.value}`);
        keepTimeout();
    };

    const {keepTimeout} = useTimeout(COPIED_DELAY_MS, () => {
        setIsCopied(false);
    });

    const value = useMemo(() => {
        return `${props.value}`
            .split('\n')
            .map((line, i) =>
                <div key={i}>
                    {line}
                </div>);
    }, [props.value]);

    return (
        <div className={classNames(styles.valueDisplay, props.className)}>
            {
                props.label &&
                <div className={styles.label}>
                    {props.label}
                </div>
            }
            <div className={styles.value}>
                {props.value === '' ? '-' : value}
            </div>
            {
                props.withCopy && (
                    isCopied ?
                        <div className={styles.copied}>
                            <img
                                src={checkImage}
                                alt={''}
                            />
                            Copied
                        </div> :
                        <div
                            className={styles.copy}
                            onClick={onCopy}
                        >
                            Copy
                        </div>
                )
            }
        </div>
    );
};

export default ValueDisplay;
