import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import crossImage from './media/cross.svg';

import styles from './CrossButton.module.scss';

const CrossButton: FC<IProps> = (props) => {
    return (
        <button
            className={classNames(styles.crossButton, props.className)}
            onClick={props.onClick}
        >
            <img
                className={styles.crossImage}
                src={crossImage}
                alt={''}
            />
        </button>
    );
};

export default CrossButton;
