import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useEffect,
    useRef,
} from 'react';
import {
    createPortal,
} from 'react-dom';

import styles from './Modal.module.scss';

const Modal: FC<IProps> = (props) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const onClickOverlay = (e) => {
        if (!modalRef.current) {
            return;
        }
        if (modalRef.current.contains(e.target)) {
            return;
        }

        props.onClose();
    };

    useEffect(() => {
        if (!props.isOpen) {
            return;
        }

        function handleEsc(e: KeyboardEvent) {
            if (e.key !== 'Escape') {
                return;
            }

            props.onClose();
        }

        document.body.style.overflowY = 'hidden';
        window.addEventListener('keydown', handleEsc);

        return () => {
            document.body.style.overflowY = 'initial';
            window.removeEventListener('keydown', handleEsc);
        };
    }, [props.isOpen]);

    if (!props.isOpen) {
        return null;
    }

    return createPortal(
        <div
            className={styles.overlay}
            onClick={onClickOverlay}
        >
            <div className={styles.container}>
                <div
                    ref={modalRef}
                    className={classNames(styles.modal, props.className)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {props.children}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Modal;
